import React, { useEffect } from "react";
import Box from "ui-box";
import vegaEmbed from "vega-embed";
import useResizeAware from "react-resize-aware";

const VegaEmbed = React.memo(
  ({
    spec,
    specPreprocessOverrides,
    specPostprocessOverrides,
    name,
    id,
    height,
    width,
    yTitle,
    xTitle,
    xLabelPadding,
    xDomain,
    shouldDisplayAxis,
    shouldDisplayYAxisTitle,
    shouldDisplayXAxisTitle
  }) => {
    const [ResizeListener, sizes] = useResizeAware();
    const ref = React.createRef();
    const hash = Math.random()
      .toString()
      .replace(".", "");

    const drawVisualization = () => {
      if (!Object.keys(spec).length || (!sizes.width && !width)) {
        return;
      }
      const visData = prepSpec();
      vegaEmbed(`.visualization-${hash}`, visData, {
        actions: false
        // renderer: "svg"
      });
    };

    useEffect(drawVisualization, [Object.keys(spec).length, sizes.width, id]);

    const prepSpec = () => {
      const visData = { ...spec, ...specPreprocessOverrides };
      if (!visData.config) {
        visData.config = {};
      }
      if (visData.config.axis) {
        visData.config.axis.labelFont = "Nunito Sans";
        visData.config.axis.titleFont = "Nunito Sans";
      } else {
        visData.config.axis = {
          labelFont: "Nunito Sans",
          titleFont: "Nunito Sans"
        };
      }
      if (visData.config.title) {
        visData.config.title.font = "Nunito Sans";
      } else {
        visData.config.title = { font: "Nunito Sans" };
      }

      if (visData.layer) {
        if (visData.layer[0].mark === "bar") {
          visData.layer[0].mark = { type: "bar", size: 36 };
        }

        if (xDomain) {
          if (visData.layer[0].encoding && visData.layer[0].encoding.x) {
            visData.layer[0].encoding.x.scale = {
              domain: xDomain
            };
          }
        }

        if (xLabelPadding) {
          if (visData.layer[0].encoding.x && visData.layer[0].encoding.x.axis) {
            visData.layer[0].encoding.x.axis.labelPadding = xLabelPadding;
          }
        }

        if (visData.layer[0].encoding.color) {
          visData.layer[0].encoding.color.legend = null;
          if (
            visData.layer[0].encoding.color.scale &&
            visData.layer[0].encoding.color.scale.scheme
          ) {
            delete visData.layer[0].encoding.color.scale.scheme;
          }
        }

        if (!shouldDisplayYAxisTitle || yTitle) {
          if (visData.layer[0].encoding.y) {
            visData.layer[0].encoding.y.title = yTitle || null;
          }
        }

        if (!shouldDisplayXAxisTitle || xTitle) {
          if (visData.layer[0].encoding.x) {
            visData.layer[0].encoding.x.title = xTitle || null;
          }
        }

        if (!shouldDisplayAxis) {
          visData.layer = [visData.layer[0]];
          visData.layer.forEach((layer, i) => {
            if (visData.layer[i].encoding) {
              if (visData.layer[i].encoding.x) {
                visData.layer[i].encoding.x.axis = null;
              }
              if (visData.layer[i].encoding.y) {
                visData.layer[i].encoding.y.axis = null;
              }
            }
          });
        }
      } else {
        visData.mark = { type: "bar" };
      }
      if (visData.encoding) {
        if (visData.encoding.color) {
          visData.encoding.color.legend = null;
        }

        if (!shouldDisplayYAxisTitle || yTitle) {
          if (visData.encoding.y) {
            visData.encoding.y.title = yTitle || null;
          }
        }

        if (!shouldDisplayAxis) {
          if (visData.encoding.x) {
            visData.encoding.x.axis = null;
          }
          if (visData.encoding.y) {
            visData.encoding.y.axis = null;
          }
        }
      }

      visData.backgroundColor = "#f7fafc";

      visData.width = width || sizes.width;
      visData.height = height || sizes.height;
      visData.autosize = { type: "fit", contains: "padding" };
      console.log(`drawing ${name} at ${sizes.width}`);
      const finalSpec = { ...visData, ...specPostprocessOverrides };
      console.log(finalSpec);
      return finalSpec;
    };

    console.log(name, sizes);

    return (
      <Box
        ref={ref}
        position="relative"
        width={width ? "auto" : "100%"}
        height={height ? "auto" : "100%"}
      >
        <Box
          position={height || width ? "static" : "absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
        >
          <ResizeListener />
          <Box className={`vis visualization-${hash}`} height="100%" />
        </Box>
      </Box>
    );
  }
);

VegaEmbed.defaultProps = {
  spec: {},
  specPreprocessOverrides: {},
  specPostprocessOverrides: {},
  name: "",
  shouldDisplayAxis: true,
  shouldDisplayYAxisTitle: true,
  shouldDisplayXAxisTitle: true,
  xDomain: null
};

export default VegaEmbed;
