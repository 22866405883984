import React, { useCallback } from "react";
import styled from "styled-components";
import Box from "ui-box";
import PropTypes from "prop-types";

const Tab = React.memo(({ isSelected, onClick, id, ...props }) => {
  const onClickTab = useCallback(() => onClick(id), [id]);
  return (
    <StyledTab
      {...props}
      className={isSelected && "selected"}
      onClick={onClickTab}
    />
  );
});

Tab.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const StyledTab = styled(Box)`
  line-height: 28px;
  padding: 0 8px;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  text-align: center;

  &.selected,
  &:hover {
    color: #1365bd;
    background-color: rgba(19, 101, 189, 0.09);
  }
`;

export default Tab;
