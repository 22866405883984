import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Badge } from "evergreen-ui";
import { Spinner } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import {
  fetchData,
  isFeatureAProperty,
  toSigFig,
  getFormattedValue
} from "helpers";

import Feature from "components/Feature";
import Tag from "components/base/Tag";
import Content from "components/layout/Content";
import Panel from "components/layout/Panel";
import Flex from "components/layout/Flex";
import VegaEmbed from "components/VegaEmbed";

const sortFeaturesToTop = features => (a, b) => {
  const isFeatureAAProperty = isFeatureAProperty(a, features);
  const isFeatureBAProperty = isFeatureAProperty(b, features);

  if (isFeatureAAProperty && !isFeatureBAProperty) {
    return -1;
  }
  if (isFeatureBAProperty && !isFeatureAAProperty) {
    return 1;
  }

  return 0;
};

const includePropertyFeatures = metadata => r => {
  return isFeatureAProperty(r, metadata);
};

const excludePropertyFeatures = metadata => r => {
  return !isFeatureAProperty(r, metadata);
};

const propertyOperationToNaturalLanguage = operation => {
  switch (operation) {
    case "==":
      return "";
    case "!=":
      return "non-";
    default:
      return "";
  }
};

const operationToNaturalLanguage = operation => {
  switch (operation) {
    case ">":
      return "is greater than";
    case ">=":
      return "is greater than or equal to";
    case "<":
      return "is less than";
    case "<=":
      return "is less than or equal to";
    default:
      return "";
  }
};

const MissionControlPage = React.memo(() => {
  const [rules, updateRules] = useState(null);
  const [segments, updateSegments] = useState(null);
  const [metadata, updateMetadata] = useState(null);
  const [customers, updateCustomers] = useState(null);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, []);

  const setData = async () => {
    updateSegments(await fetchData("segments", "/personas?limit=1"));
    updateMetadata(await fetchData("metadata", "/metadata"));
    updateRules(
      await fetchData("rules", "/rules?max_num_usage_rules=2&limit=1")
    );
    updateCustomers(await fetchData("customers", "/customers"));
  };

  useEffect(() => {
    setData();
  }, []);

  if (!segments || !metadata || !rules || !customers) {
    return (
      <Content alignItems="center" justifyContent="center">
        <Spinner />
      </Content>
    );
  }
  console.log(customers);

  const rule = rules[0];
  const segment = segments[0];

  return (
    <Content flexWrap="nowrap">
      <Box width="100%">
        <Panel height={440} width="100%">
          <Panel.HeaderSection>
            <Panel.Title>Churn Rate over Time</Panel.Title>
          </Panel.HeaderSection>
          <Panel.OffsetSection paddingTop={16}>
            <VegaEmbed
              spec={customers}
              id="churn-over-time"
              shouldDisplayXAxisTitle={false}
              xDomain={[
                { year: 2019, month: "mar", date: 1 },
                { year: 2016, month: "jul", date: 1 }
              ]}
            />
          </Panel.OffsetSection>
        </Panel>
        <Panel flexGrow={1} width="100%">
          <Panel.HeaderSection>
            <Flex alignItems="center">
              <Panel.Title>Rule</Panel.Title>
              <Box marginLeft="auto">
                <Link to="/rules">More Rules</Link>
              </Box>
            </Flex>
          </Panel.HeaderSection>
          <Panel.OffsetSection>
            <Flex>
              <Box>
                <Box marginBottom={40} paddingRight={60}>
                  The{" "}
                  <Box is="span" fontWeight={600}>
                    {(rule["percent of total"] * 100).toFixed(0)}%
                  </Box>{" "}
                  of
                  {rule.ruleset
                    .filter(includePropertyFeatures(metadata.features))
                    .map(ruleset => (
                      <span key={ruleset.display_feature_name}>
                        {" "}
                        {propertyOperationToNaturalLanguage(ruleset.operation)}
                        {ruleset.value}{" "}
                        <Tag>{ruleset.display_feature_name} </Tag>
                      </span>
                    ))}{" "}
                  users with
                  {rule.ruleset
                    .filter(excludePropertyFeatures(metadata.features))
                    .sort(sortFeaturesToTop(metadata.features))
                    .map((ruleset, i) => (
                      <span key={ruleset.display_feature_name}>
                        {i === 0 && " "}
                        {i > 0 && i !== rule.ruleset.length - 1 && ", "}
                        {i === rule.ruleset.length - 1 && ", and "}
                        <Tag>{ruleset.display_feature_name}</Tag>{" "}
                        {operationToNaturalLanguage(ruleset.operation)}{" "}
                        <Box is="span" fontWeight={600}>
                          {getFormattedValue(
                            metadata.features[ruleset.original_feature_id],
                            ruleset.value
                          )}
                        </Box>
                      </span>
                    ))}
                  {" are "}
                  <span
                    style={{
                      color:
                        rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                        rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                          ? "green"
                          : "red",
                      fontWeight: "bold"
                    }}
                  >
                    {Math.abs(
                      toSigFig(
                        (rule.outcomes[metadata.dependent_ids[0]].subset.avg -
                          rule.outcomes[metadata.dependent_ids[0]].inverse
                            .avg) *
                          100,
                        2
                      )
                    )}
                    %
                  </span>
                  {rule.outcomes[metadata.dependent_ids[0]].subset.avg >
                  rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                    ? " more "
                    : " less "}
                  {"likely to churn than those who do not."}
                </Box>

                <Box>
                  {rule.ruleset
                    .sort(sortFeaturesToTop(metadata.features))
                    .map(featureRule => (
                      <Feature
                        rule={{
                          ...featureRule,
                          feature_histogram: rule.feature_histograms.find(
                            histo =>
                              histo.feature_id ===
                                featureRule.original_feature_id ||
                              histo.feature_id ===
                                featureRule.original_feature_id +
                                  "_" +
                                  featureRule.value
                          )
                        }}
                        key={featureRule.original_feature_id}
                      />
                    ))}
                </Box>
              </Box>
              <StyledRelevance>
                <div>
                  {(rule["percent of total"] * 100).toFixed(0)}% of users
                </div>
                <Divider />
                <div>
                  <Badge
                    marginRight={4}
                    color={
                      rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                      rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                        ? "green"
                        : "red"
                    }
                  >
                    {rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                    rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                      ? "low"
                      : "high"}
                  </Badge>
                  churn:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].subset.avg * 100,
                    2
                  )}
                  % ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].subset.err * 100,
                    2
                  )}
                  %
                </div>
                <div>
                  inverse churn:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].inverse.avg * 100,
                    2
                  )}
                  % ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].inverse.err * 100,
                    2
                  )}
                  %
                </div>
                <div>
                  global churn:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].global.avg * 100,
                    2
                  )}
                  % ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[0]].global.err * 100,
                    2
                  )}
                  %
                </div>
                <Divider />
                <div>
                  <Badge
                    marginRight={8}
                    color={
                      rule.outcomes[metadata.dependent_ids[1]].subset.avg <
                      rule.outcomes[metadata.dependent_ids[1]].inverse.avg
                        ? "red"
                        : "green"
                    }
                  >
                    {rule.outcomes[metadata.dependent_ids[1]].subset.avg <
                    rule.outcomes[metadata.dependent_ids[1]].inverse.avg
                      ? "low"
                      : "high"}
                  </Badge>
                  revenue:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].subset.avg,
                    2,
                    true
                  )}{" "}
                  ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].subset.err,
                    2,
                    true
                  )}
                </div>
                <div>
                  inverse revenue:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].inverse.avg,
                    2,
                    true
                  )}{" "}
                  ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].inverse.err,
                    2,
                    true
                  )}
                </div>
                <div>
                  global revenue:{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].global.avg,
                    2,
                    true
                  )}{" "}
                  ±{" "}
                  {toSigFig(
                    rule.outcomes[metadata.dependent_ids[1]].global.err,
                    2,
                    true
                  )}
                </div>
              </StyledRelevance>
            </Flex>
          </Panel.OffsetSection>
        </Panel>
        <Row>
          <Panel height={440}>
            <Panel.HeaderSection>
              <Flex alignItems="center">
                <Panel.Title>Top Persona Set by Churn</Panel.Title>
                <Box marginLeft="auto">
                  <Link to="/personas">More Personas</Link>
                </Box>
              </Flex>
            </Panel.HeaderSection>
            <Panel.OffsetSection paddingTop={16}>
              <VegaEmbed
                spec={segment[`mean_${metadata.dependent_ids[0]}_vg_spec`]}
                name="churn"
                yTitle="Churn"
                xTitle="Persona"
                id="churn-by-persona"
              />
            </Panel.OffsetSection>
          </Panel>{" "}
          <Panel height={440}>
            <Panel.HeaderSection>
              <Flex alignItems="center">
                <Panel.Title>Top Persona Set by Revenue</Panel.Title>
                <Box marginLeft="auto">
                  <Link to="/personas">More Personas</Link>
                </Box>
              </Flex>
            </Panel.HeaderSection>
            <Panel.OffsetSection paddingTop={16}>
              <VegaEmbed
                spec={segment[`mean_${metadata.dependent_ids[1]}_vg_spec`]}
                name="revenue"
                yTitle="Revenue"
                xTitle="Persona"
                id="revenue-by-persona"
              />
            </Panel.OffsetSection>
          </Panel>
        </Row>
      </Box>
    </Content>
  );
});

const Divider = styled.hr`
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledRelevance = styled(Box)`
  margin-left: auto;
  text-align: right;
  flex-shrink: 0;
`;

const Row = styled(Box)`
  display: flex;

  & > * {
    flex-grow: 1;
    width: 100%;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export default MissionControlPage;
