import React from "react";
import styled from "styled-components";
import Box from "ui-box";

const Panel = styled(Box)`
  display: flex;
  flex-direction: column;

  max-width: calc(100vw - 40px);
  margin-bottom: 20px;

  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);

  .vega-embed {
    padding: 0;
  }

  .visualization {
    width: 100%;
  }
`;

Panel.Title = styled(Box)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

Panel.Description = props => (
  <Box
    maxWidth={550}
    marginTop={10}
    marginBottom={6}
    color="#4f566b"
    {...props}
  />
);

Panel.HeaderSection = styled(Box)`
  background-color: white;
  padding: 16px 20px;
  box-shadow: inset 0 -1px #e3e8ee;
  border-radius: 6px 6px 0 0;
`;

Panel.OffsetSection = styled(Box)`
  background-color: #f7fafc;
  flex-grow: 1;
  overflow: scroll;
  border-radius: 0 0 6px 6px;

  svg {
    background-color: #f7fafc !important;
  }
`;
Panel.OffsetSection.defaultProps = {
  paddingX: 20,
  paddingY: 16
};

export default Panel;
