import React from "react";
import Box from "ui-box";

const Tag = props => (
  <Box
    display="inline-flex"
    paddingX={6}
    paddingY={2}
    marginY={2}
    color="rgba(0,0,0,0.7)"
    backgroundColor="rgba(0,0,0,0.06)"
    borderRadius={6}
    {...props}
  />
);

export default Tag;
