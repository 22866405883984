import React, { useState, useEffect } from "react";
import { Badge } from "evergreen-ui";
import Box from "ui-box";
import Flex from "components/layout/Flex";
import Tag from "components/base/Tag";
import VegaEmbed from "components/VegaEmbed";
import { fetchData, getRuleFeatureCategory, getFormattedValue } from "helpers";

const minichartSize = {
  width: 60,
  height: 50
};

const minichartConfig = {
  config: {
    view: {
      width: minichartSize.width,
      height: minichartSize.height,
      stroke: "transparent"
    },
    axis: {
      grid: false
    }
  },
  width: minichartSize.width,
  height: minichartSize.height
};

const FormattedTypeBadge = React.memo(({ rule }) => {
  const [features, updateFeatures] = useState(null);
  const getData = async () =>
    updateFeatures((await fetchData("metadata", "/metadata")).features);

  useEffect(() => {
    getData();
  }, []);

  if (!features) {
    return null;
  }

  const category = getRuleFeatureCategory(rule, features);

  switch (category) {
    case "property":
      return <Badge color="orange">property</Badge>;
    case "usage":
      return <Badge color="blue">usage</Badge>;
    case "end_user" /* For now since the distinction is not clear in the sample data */:
      return <Badge color="purple">end user</Badge>;
    default:
      return category && <Badge>{category}</Badge>;
  }
});

const Operation = ({ operation }) => {
  switch (operation) {
    case ">=":
      return "≥";
    case "<=":
      return "≤";
    case "==":
      return "=";
    case "!=":
      return "≠";
    default:
      return operation;
  }
};

const Value = React.memo(({ rule }) => {
  const [features, updateFeatures] = useState(null);
  const getData = async () =>
    updateFeatures((await fetchData("metadata", "/metadata")).features);

  useEffect(() => {
    getData();
  }, []);

  if (!features) {
    return null;
  }

  return (
    <Box is="span" fontWeight={600}>
      {getFormattedValue(features[rule.original_feature_id], rule.value)}
    </Box>
  );
});

const Feature = React.memo(({ rule }) => (
  <Flex marginBottom={10} alignItems="center">
    <Box
      height={minichartSize.height}
      width={minichartSize.width}
      marginTop={-10}
      marginRight={4}
    >
      {rule.feature_histogram && (
        <VegaEmbed
          spec={rule.feature_histogram.vg}
          specPostprocessOverrides={minichartConfig}
          shouldDisplayAxis={false}
        />
      )}
    </Box>
    <Box is="span" marginRight={10}>
      <Tag>{rule.display_feature_name}</Tag>{" "}
      <Operation operation={rule.operation} /> <Value rule={rule} />
    </Box>
    <FormattedTypeBadge rule={rule} />
  </Flex>
));

export default Feature;
