import React, { useEffect } from "react";
import { hot } from "react-hot-loader";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import uuid from "uuid";
import MissionControlPage from "./pages/MissionControlPage";
import DataPage from "./pages/DataPage";
import PersonasPage from "./pages/PersonasPage";
import RulesPage from "./pages/RulesPage";
import Navbar from "./components/Navbar";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #283640;
    cursor: auto!important;
  }

  html, body, #app {
    height: 100%;
  }

  a, a:hover {
    text-decoration: none;
  }

  #app {
    display: flex;
    flex-direction: column;

    & > * {
      flex-shrink: 0;
    }
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
  }
`;

const App = () => {
  useEffect(() => {
    if (window.analytics) {
      const storedId = localStorage.getItem("q_userid");
      if (storedId) {
        window.analytics.identify(storedId);
      } else {
        const newId = uuid.v4();
        localStorage.setItem("q_userid", newId);
        window.analytics.identify(newId);
      }
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <Router>
        <>
          <Navbar />
          <Switch>
            <Route exact path="/" render={() => <MissionControlPage />} />
            <Route path="/data" render={() => <DataPage />} />
            <Route path="/personas" component={() => <PersonasPage />} />
            <Route path="/rules" component={() => <RulesPage />} />
            <Redirect from="/" to="/" />
          </Switch>
        </>
      </Router>
    </>
  );
};

export default hot(module)(App);
