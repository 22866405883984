import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Badge } from "evergreen-ui";
import { Spinner } from "@blueprintjs/core";

import {
  fetchData,
  useKeyPress,
  toSigFig,
  isFeatureAProperty,
  getFormattedValue
} from "helpers";

import Content from "components/layout/Content";
import Panel from "components/layout/Panel";
import Flex from "components/layout/Flex";
import Tab from "components/base/Tab";
import Tag from "components/base/Tag";
import Feature from "components/Feature";

const sortFeaturesToTop = features => (a, b) => {
  const isFeatureAAProperty = isFeatureAProperty(a, features);
  const isFeatureBAProperty = isFeatureAProperty(b, features);

  if (isFeatureAAProperty && !isFeatureBAProperty) {
    return -1;
  }
  if (isFeatureBAProperty && !isFeatureAAProperty) {
    return 1;
  }

  return 0;
};

const includePropertyFeatures = metadata => r => {
  return isFeatureAProperty(r, metadata);
};

const excludePropertyFeatures = metadata => r => {
  return !isFeatureAProperty(r, metadata);
};

const propertyOperationToNaturalLanguage = operation => {
  switch (operation) {
    case "==":
      return "";
    case "!=":
      return "non-";
    default:
      return "";
  }
};

const operationToNaturalLanguage = operation => {
  switch (operation) {
    case ">":
      return "is greater than";
    case ">=":
      return "is greater than or equal to";
    case "<":
      return "is less than";
    case "<=":
      return "is less than or equal to";
    default:
      return "";
  }
};

const RulesPage = React.memo(() => {
  const [rules, updateRules] = useState([]);
  const [metadata, updateMetadata] = useState(null);
  const [selectedTab, updateSelectedTab] = useState(0);
  const hasPressedUp = useKeyPress("ArrowLeft");
  const hasPressedDown = useKeyPress("ArrowRight");

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, []);

  useEffect(() => {
    if (hasPressedUp) {
      if (selectedTab > 0) {
        updateSelectedTab(selectedTab - 1);
      }
    }
  }, [hasPressedUp]);

  useEffect(() => {
    if (hasPressedDown) {
      if (selectedTab < rules.length - 1) {
        updateSelectedTab(selectedTab + 1);
      }
    }
  }, [hasPressedDown]);

  useEffect(() => {
    setData();
  }, []);

  const setData = async () => {
    updateRules(
      await fetchData(
        "rules",
        "/rules?max_num_rules=3&min_num_property_rules=1&max_num_usage_rules=2&max_num_property_rules=2&max_num_end_user_rules=2&max_num_other_rules=2"
      )
    );
    updateMetadata(await fetchData("metadata", "/metadata"));
  };

  if (!rules.length || !metadata) {
    return (
      <Content alignItems="center" justifyContent="center">
        <Spinner />
      </Content>
    );
  }

  const rule = rules[selectedTab];
  const ruleNumber = selectedTab + 1;

  console.log("rules, metadata");
  console.log(rules, metadata);

  return (
    <Content flexWrap="nowrap">
      <div>
        {rules.map((r, i) => (
          <Tab
            key={r.qnt_id}
            id={i}
            isSelected={selectedTab === i}
            onClick={updateSelectedTab}
          >
            {i + 1}
          </Tab>
        ))}
      </div>
      <Panel flexGrow={1}>
        <Panel.HeaderSection>
          <Flex alignItems="center">
            <Panel.Title>Rule {ruleNumber}</Panel.Title>
            <StyledRelevance>
              Relevance: <Tag>{(rule.relevance * 100).toFixed(0)}</Tag>
            </StyledRelevance>
          </Flex>
        </Panel.HeaderSection>
        <Panel.OffsetSection>
          <Flex>
            <Box marginBottom={40}>
              The{" "}
              <Box is="span" fontWeight={600}>
                {(rule["percent of total"] * 100).toFixed(0)}%
              </Box>{" "}
              of
              {rule.ruleset
                .filter(includePropertyFeatures(metadata.features))
                .map(ruleset => (
                  <span key={ruleset.display_feature_name}>
                    {" "}
                    {propertyOperationToNaturalLanguage(ruleset.operation)}
                    {ruleset.value} <Tag>{ruleset.display_feature_name} </Tag>
                  </span>
                ))}{" "}
              users with
              {rule.ruleset
                .filter(excludePropertyFeatures(metadata.features))
                .sort(sortFeaturesToTop(metadata.features))
                .map((ruleset, i) => (
                  <span key={ruleset.display_feature_name}>
                    {i === 0 && " "}
                    {i > 0 && i !== rule.ruleset.length - 1 && ", "}
                    {i === rule.ruleset.length - 1 && ", and "}
                    <Tag>{ruleset.display_feature_name}</Tag>{" "}
                    {operationToNaturalLanguage(ruleset.operation)}{" "}
                    <Box is="span" fontWeight={600}>
                      {getFormattedValue(
                        metadata.features[ruleset.original_feature_id],
                        ruleset.value
                      )}
                    </Box>
                  </span>
                ))}
              {" are "}
              <span
                style={{
                  color:
                    rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                    rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                      ? "green"
                      : "red",
                  fontWeight: "bold"
                }}
              >
                {Math.abs(
                  toSigFig(
                    (rule.outcomes[metadata.dependent_ids[0]].subset.avg -
                      rule.outcomes[metadata.dependent_ids[0]].inverse.avg) *
                      100,
                    2
                  )
                )}
                %
              </span>
              {rule.outcomes[metadata.dependent_ids[0]].subset.avg >
              rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                ? " more "
                : " less "}
              {"likely to churn than those who do not."}
            </Box>
          </Flex>
          <Flex>
            <Box>
              {rule.ruleset
                .sort(sortFeaturesToTop(metadata.features))
                .map(featureRule => (
                  <Feature
                    rule={{
                      ...featureRule,
                      feature_histogram: rule.feature_histograms.find(
                        histo =>
                          histo.feature_id ===
                            featureRule.original_feature_id ||
                          histo.feature_id ===
                            featureRule.original_feature_id +
                              "_" +
                              featureRule.value
                      )
                    }}
                    key={featureRule.original_feature_id}
                  />
                ))}
              <Box marginTop={50} paddingLeft={15} paddingRight={35}>
                <Box fontWeight={600} marginBottom={10}>
                  Companies
                </Box>
                <Flex flexWrap="wrap">
                  {rule.exemplars[metadata.name_ids[0]]
                    .filter(name => name !== "gmail.com")
                    .map(name => (
                      <Box
                        key={name}
                        width={200}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        paddingRight={20}
                      >
                        {name}
                      </Box>
                    ))}
                </Flex>
              </Box>
            </Box>
            <StyledRelevance>
              <div>{(rule["percent of total"] * 100).toFixed(0)}% of users</div>
              <Divider />
              <div>
                <Badge
                  marginRight={4}
                  color={
                    rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                    rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                      ? "green"
                      : "red"
                  }
                >
                  {rule.outcomes[metadata.dependent_ids[0]].subset.avg <
                  rule.outcomes[metadata.dependent_ids[0]].inverse.avg
                    ? "low"
                    : "high"}
                </Badge>
                churn:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].subset.avg * 100,
                  2
                )}
                % ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].subset.err * 100,
                  2
                )}
                %
              </div>
              <div>
                inverse churn:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].inverse.avg * 100,
                  2
                )}
                % ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].inverse.err * 100,
                  2
                )}
                %
              </div>
              <div>
                global churn:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].global.avg * 100,
                  2
                )}
                % ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[0]].global.err * 100,
                  2
                )}
                %
              </div>
              <Divider />
              <div>
                <Badge
                  marginRight={8}
                  color={
                    rule.outcomes[metadata.dependent_ids[1]].subset.avg <
                    rule.outcomes[metadata.dependent_ids[1]].inverse.avg
                      ? "red"
                      : "green"
                  }
                >
                  {rule.outcomes[metadata.dependent_ids[1]].subset.avg <
                  rule.outcomes[metadata.dependent_ids[1]].inverse.avg
                    ? "low"
                    : "high"}
                </Badge>
                revenue:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].subset.avg,
                  2,
                  true
                )}{" "}
                ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].subset.err,
                  2,
                  true
                )}
              </div>
              <div>
                inverse revenue:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].inverse.avg,
                  2,
                  true
                )}{" "}
                ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].inverse.err,
                  2,
                  true
                )}
              </div>
              <div>
                global revenue:{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].global.avg,
                  2,
                  true
                )}{" "}
                ±{" "}
                {toSigFig(
                  rule.outcomes[metadata.dependent_ids[1]].global.err,
                  2,
                  true
                )}
              </div>
            </StyledRelevance>
          </Flex>
        </Panel.OffsetSection>
      </Panel>
    </Content>
  );
});

const Divider = styled.hr`
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledRelevance = styled(Box)`
  margin-left: auto;
  text-align: right;
  flex-shrink: 0;
`;

export default RulesPage;
