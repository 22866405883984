import React from "react";
import styled from "styled-components";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Icon } from "evergreen-ui";
import {
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind
} from "@blueprintjs/core";
import Box from "ui-box";

const SettingsMenu = ({ history }) => (
  <Menu>
    <MenuItem text="Data" onClick={() => history.push("/data")} />
    <MenuItem disabled text="Features" />
    <MenuItem disabled text="Customers" />
    <Menu.Divider />
    <MenuItem disabled text="Account" />
  </Menu>
);

// <NavBarLink to="/data">Data</NavBarLink>

const Navbar = ({ history }) => (
  <StyledNavbar>
    <Link to="/">
      <StyledLogoType>Quantifai</StyledLogoType>
    </Link>
    <NavBarLink exact to="/">
      Mission Control
    </NavBarLink>
    <Divider />
    <NavBarLink to="/rules">Rules</NavBarLink>
    <NavBarLink to="/personas">Personas</NavBarLink>
    <Divider />
    <NavBarLink to="/analysis" disabled>
      Analysis
    </NavBarLink>
    <NavBarLink to="/experiments" disabled>
      Experiments
    </NavBarLink>
    <Box marginLeft="auto">
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        content={<SettingsMenu history={history} />}
        position="auto-end"
      >
        <NavBarLink is="a">
          Settings <Icon icon="caret-down" marginLeft={4} />
        </NavBarLink>
      </Popover>
    </Box>
  </StyledNavbar>
);

const NavBarLink = styled(Box)`
  font-weight: 600;
  color: #283640;
  padding: 0 12px;
  display: inline-flex;
  align-items: center;

  &.active {
    color: #1365bd;
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
    `};
`;
NavBarLink.defaultProps = {
  is: NavLink
};

const Divider = () => (
  <Box>
    <Icon icon="dot" size={10} marginTop={2} marginX={8} opacity={0.4} />
  </Box>
);

const StyledLogoType = styled.div`
  font-size: 1.125rem;
  font-weight: 800;
  user-select: none;
  color: #182026;
  padding-right: 17px;
  margin-right: 16px;
`;

const StyledNavbar = styled.div`
  display: flex;
  padding: 16px 38px;
  background-color: white;
  z-index: 1;
  align-items: center;
`;

export default withRouter(Navbar);
