import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Spinner } from "@blueprintjs/core";
import { fetchData, toSigFig } from "helpers";

import Content from "components/layout/Content";
import Panel from "components/layout/Panel";
import Flex from "components/layout/Flex";
import Tab from "components/base/Tab";
import VegaEmbed from "components/VegaEmbed";

const getNameFromPersonaId = (personaSet, personaId) =>
  personaSet.personas_to_labels[personaId];

const sortPersonas = personaSet => (a, b) => {
  const aName = getNameFromPersonaId(personaSet, a.persona_id);
  const bName = getNameFromPersonaId(personaSet, b.persona_id);

  if (aName < bName) {
    return -1;
  }
  if (bName > aName) {
    return 1;
  }
  return 0;
};

const getDatasetStats = (dataset, countVariable) =>
  dataset.reduce(
    ({ minGroup, maxGroup, mean }, group) => ({
      minGroup:
        minGroup === 0 ||
        (group[countVariable] < minGroup[countVariable] &&
          group[countVariable] !== 0)
          ? group
          : minGroup,
      maxGroup:
        group[countVariable] > maxGroup[countVariable] ? group : maxGroup,
      mean: mean + group[countVariable] / dataset.length
    }),
    {
      minGroup: dataset[0],
      maxGroup: dataset[0],
      mean: 0
    }
  );

const PersonasPage = React.memo(() => {
  const [segments, updateSegments] = useState(null);
  const [metadata, updateMetadata] = useState(null);
  const [selectedTab, updateSelectedTab] = useState(0);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, []);

  const setData = async () => {
    updateSegments(await fetchData("segments", "/personas"));
    updateMetadata(await fetchData("metadata", "/metadata"));
  };

  useEffect(() => {
    setData();
  }, []);

  if (!segments || !metadata) {
    return (
      <Content alignItems="center" justifyContent="center">
        <Spinner />
      </Content>
    );
  }

  const segment = segments[selectedTab];

  const churnSpec = segment[`mean_${metadata.dependent_ids[0]}_vg_spec`];
  const churnSpecDataset = churnSpec.datasets[churnSpec.layer[0].data.name];
  const churnSpecStats = getDatasetStats(churnSpecDataset, "mean");

  const countSpec = segment.count_vg_spec;
  const countSpecDataset = countSpec.datasets[countSpec.layer[0].data.name];
  const countSpecStats = getDatasetStats(countSpecDataset, "counts");

  const revenueSpec = segment[`mean_${metadata.dependent_ids[1]}_vg_spec`];
  const revenueSpecDataset =
    revenueSpec.datasets[revenueSpec.layer[1].data.name];
  const revenueSpecStats = getDatasetStats(revenueSpecDataset, "mean");

  return (
    <Content flexWrap="nowrap">
      <div>
        {segments.map((s, i) => (
          <Tab
            key={s.silhouette}
            id={i}
            isSelected={selectedTab === i}
            onClick={updateSelectedTab}
          >
            {i + 1}
          </Tab>
        ))}
      </div>
      <Flex flexWrap="wrap">
        <Row>
          <Panel height={440}>
            <Panel.HeaderSection>
              <Panel.Title>Churn by Persona</Panel.Title>
              <Panel.Description>
                Of personas that churn, companies in Persona{" "}
                {churnSpecStats.maxGroup.label} churn at the highest rate of{" "}
                {Math.round(churnSpecStats.maxGroup.mean * 100)}
                %, while companies in Persona {
                  churnSpecStats.minGroup.label
                }{" "}
                churn at the lowest rate of{" "}
                {Math.round(churnSpecStats.minGroup.mean * 100)}%. The average
                persona churn rate is {Math.round(churnSpecStats.mean * 100)}%.
              </Panel.Description>
            </Panel.HeaderSection>
            <Panel.OffsetSection paddingTop={16}>
              <VegaEmbed
                spec={churnSpec}
                name="churn"
                yTitle="Churn"
                xTitle="Persona"
                id={selectedTab}
              />
            </Panel.OffsetSection>
          </Panel>
          <Panel height={440}>
            <Panel.HeaderSection>
              <Panel.Title>Persona Size</Panel.Title>
              <Panel.Description>
                Persona {countSpecStats.maxGroup.label} is the largest Persona
                at {countSpecStats.maxGroup.counts} companies, while Persona{" "}
                {countSpecStats.minGroup.label} is the smallest at{" "}
                {countSpecStats.minGroup.counts} companies. The average amount
                of companies per persona is {Math.round(countSpecStats.mean)}.
              </Panel.Description>
            </Panel.HeaderSection>
            <Panel.OffsetSection paddingTop={16}>
              <VegaEmbed
                spec={countSpec}
                name="count"
                yTitle="Number of Users"
                xTitle="Persona"
                id={selectedTab}
              />
            </Panel.OffsetSection>
          </Panel>
        </Row>
        <Row>
          <Panel height={440}>
            <Panel.HeaderSection>
              <Panel.Title>Revenue by Persona</Panel.Title>
              <Panel.Description>
                Companies in Persona {revenueSpecStats.maxGroup.label} bring in
                the most revenue at $
                {toSigFig(revenueSpecStats.maxGroup.mean, 2, true)}, while
                companies in Persona {revenueSpecStats.minGroup.label} bring in
                the least at $
                {toSigFig(revenueSpecStats.minGroup.mean, 2, true)}. The average
                revenue per persona is $
                {toSigFig(revenueSpecStats.mean, 2, true)}.
              </Panel.Description>
            </Panel.HeaderSection>
            <Panel.OffsetSection paddingTop={16}>
              <VegaEmbed
                spec={revenueSpec}
                name="revenue"
                yTitle="Revenue"
                xTitle="Persona"
                id={selectedTab}
              />
            </Panel.OffsetSection>
          </Panel>
        </Row>
        <Panel width="100%" maxWidth="800">
          <Panel.HeaderSection>
            <Panel.Title>Persona Breakdown</Panel.Title>
            <Panel.Description maxWidth="100%">
              To understand the differences between Personas, we've highlighted
              whether each Persona has a relatively high or low value across
              some key attributes.
            </Panel.Description>
          </Panel.HeaderSection>
          <Panel.OffsetSection paddingTop={16}>
            <Box marginBottom={20}>
              <VegaEmbed
                spec={{
                  ...segment.selected_features_heatmap_vg_spec,
                  config: {
                    ...segment.selected_features_heatmap_vg_spec.config,
                    range: {
                      category: ["#ffbcac", "#7fd1de", "#7fd1de"]
                    }
                  }
                }}
                name="breakdown"
                height={40 + 40 * segment.num_features}
                id={selectedTab}
                xLabelPadding={12}
                shouldDisplayYAxisTitle={false}
              />
            </Box>
          </Panel.OffsetSection>
        </Panel>
        <Panel>
          <Panel.HeaderSection>
            <Panel.Title>Companies in Persona</Panel.Title>
          </Panel.HeaderSection>
          <Panel.OffsetSection paddingTop={16}>
            {segment.exemplars.sort(sortPersonas(segment)).map(ex => (
              <Flex marginBottom={35} key={ex.persona_id}>
                <Box fontWeight={700} marginRight={48} whiteSpace="nowrap">
                  Persona {getNameFromPersonaId(segment, ex.persona_id)}
                </Box>
                <Flex flexWrap="wrap">
                  {Object.values(ex.exemplars[metadata.name_ids[0]])
                    .filter(name => name !== "gmail.com")
                    .map(name => (
                      <Box
                        key={name}
                        width={200}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        paddingRight={20}
                      >
                        {name}
                      </Box>
                    ))}
                </Flex>
              </Flex>
            ))}
          </Panel.OffsetSection>
        </Panel>
      </Flex>
    </Content>
  );
});

const Row = styled(Box)`
  display: flex;

  & > * {
    flex-grow: 1;
    width: 100%;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export default PersonasPage;
