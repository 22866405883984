import styled from "styled-components";
import Box from "ui-box";

const Content = styled(Box)`
  & > * {
    margin-left: 20px;
  }
`;

Content.defaultProps = {
  ...Box.defaultProps,
  backgroundColor: "#e3e8ee",
  flexGrow: 1,
  display: "flex",
  flexWrap: "wrap",
  paddingX: 20,
  paddingTop: 20,
  paddingBottom: 80
};

export default Content;
