import React, { useState, useEffect } from "react";
import { TabNavigation, Tab } from "evergreen-ui";
import { Cell, Column, Table } from "@blueprintjs/table";
import { Spinner } from "@blueprintjs/core";
import { fetchData } from "../../helpers";

import Content from "../../components/layout/Content";
import SecondBar from "../../components/layout/SecondBar";
import Panel from "../../components/layout/Panel";

const DataPage = React.memo(() => {
  const [records, updateRecords] = useState([]);
  const [metadata, updateMetadata] = useState(null);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  }, []);

  const setData = async () => {
    updateRecords(await fetchData("data", "/data?orient=records&limit=50"));
    updateMetadata(await fetchData("metadata", "/metadata"));
  };

  useEffect(() => {
    setData();
  }, []);

  if (!records.length || !metadata) {
    return (
      <Content alignItems="center" justifyContent="center">
        <Spinner />
      </Content>
    );
  }

  const columns =
    (records.length && Object.keys(records[0]))
      .filter(featureId => !featureId.includes("log_of_"))
      .map(featureId => ({
        id: featureId,
        name: metadata.features[featureId].display_name
      })) || [];

  const cellRenderer = column => rowIndex => (
    <Cell>{records[rowIndex][column]}</Cell>
  );

  return (
    <>
      <SecondBar>
        <TabNavigation>
          {["Inspect"].map((tab, index) => (
            <Tab
              key={tab}
              is="a"
              href="#"
              fontSize="16px"
              fontFamily="Nunito Sans"
              id={tab}
              isSelected={index === 0}
            >
              {tab}
            </Tab>
          ))}
        </TabNavigation>
      </SecondBar>
      <Content>
        <Panel maxHeight="calc(100vh - 153px)">
          <Panel.HeaderSection>
            <Panel.Title>Inspect Data</Panel.Title>
          </Panel.HeaderSection>
          <Panel.OffsetSection paddingX={0} paddingY={0}>
            <Table numRows={records.length}>
              {columns.map(column => (
                <Column
                  key={column.id}
                  name={column.name}
                  cellRenderer={cellRenderer(column.id)}
                />
              ))}
            </Table>
          </Panel.OffsetSection>
        </Panel>
      </Content>
    </>
  );
});

export default DataPage;
