import { useState, useEffect } from "react";
import Dexie from "dexie";

const VERSION = 7;

let API_HOST = "//quantifai-backend.herokuapp.com";
if (process.env.NODE_ENV !== "production") {
  API_HOST = "//localhost:5000";
}

const db = new Dexie("QuantifaiDatabase");
db.version(1).stores({
  rules: "id,value",
  segments: "id,value",
  data: "id,value"
});
db.version(2).stores({
  rules: "id,value",
  segments: "id,value",
  data: "id,value",
  metadata: "id,value"
});
db.version(3).stores({
  rules: "id,value",
  segments: "id,value",
  data: "id,value",
  metadata: "id,value",
  customers: "id,value"
});

export const fetchData = async (table, path) => {
  let pathWithVersion = path;
  if (pathWithVersion.includes("?")) {
    pathWithVersion += "&";
  } else {
    pathWithVersion += "?";
  }
  pathWithVersion += "v=" + VERSION;

  const items = await db[table].get(pathWithVersion);
  if (items) {
    console.log("items", items);
    return items.value;
  }

  const response = await fetch(API_HOST + pathWithVersion);
  const responseJson = await response.json();
  db[table].add({ id: pathWithVersion, value: responseJson });

  return responseJson;
};

export const useKeyPress = targetKey => {
  const [state, setState] = useState(false);

  const regularDownHandler = e => {
    e.preventDefault();
    if (e.key === targetKey) {
      setState(true);
    }
  };

  const regularUpHandler = e => {
    e.preventDefault();
    if (e.key === targetKey) {
      setState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", regularDownHandler);
    window.addEventListener("keyup", regularUpHandler);
    return () => {
      window.removeEventListener("keydown", regularDownHandler);
      window.removeEventListener("keyup", regularUpHandler);
    };
  }, [targetKey, useKeyPress]);

  return state;
};

export const isFeatureAProperty = (ruleset, features) => {
  return getRuleFeatureCategory(ruleset, features) === "property";
};

export const getRuleFeatureCategory = (ruleset, features) => {
  const featureMetadata = features[ruleset.original_feature_id];
  return featureMetadata && featureMetadata.general_category;
};

export const getFormattedValue = (feature, value) => {
  if (feature.modified_dtype === "Int64") {
    return Math.round(value);
  }
  if (value.toPrecision) {
    return toSigFig(value, 2);
  }
  return value;
};

export const toSigFig = (num, numSigFigs, useK = false) => {
  if (num === undefined || !num.toPrecision) {
    return num;
  }

  const preciseNum = num.toPrecision(numSigFigs);

  if (!preciseNum.includes("e")) {
    return preciseNum;
  }

  const exponentRegex = /e[+]([0-9]+)/gi;

  const zeroedNum = preciseNum.replace(exponentRegex, (full, match) => {
    let zeros = "";
    if (!preciseNum.includes(".")) {
      zeros += "0";
    }
    const numExponent = Number(match);
    const upperLimit = useK && numExponent > 3 ? numExponent - 3 : numExponent;
    for (let i = 1; i < upperLimit; i += 1) {
      zeros += "0";
    }
    if (useK) {
      zeros += "k";
    }
    return zeros;
  });

  return zeroedNum.replace(".", "");

  //
  //   if (Math.abs(x) < 1.0) {
  //     var e = parseInt(x.toString().split('e-')[1]);
  //     if (e) {
  //         x *= Math.pow(10,e-1);
  //         x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
  //     }
  //   } else {
  //     var e = parseInt(x.toString().split('+')[1]);
  //     if (e > 20) {
  //         e -= 20;
  //         x /= Math.pow(10,e);
  //         x += (new Array(e+1)).join('0');
  //     }
  //   }
  //   return x;
};
